<template>
  <v-container>
    <!-- <v-row justify="center" class="my-1">
      <v-col class="text-center">
        <h4 class="text-h4 font-weight-regular">Deleted User List</h4>
      </v-col>
    </v-row> -->

    <UserList show-deleted key="deleted" />
  </v-container>
</template>

<script>
export default {
  metaInfo: { title: "Deleted Users" },
  components: {
    UserList: () => import("@/views/components/User/UserList"),
  },
};
</script>
